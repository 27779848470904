<template>
  <v-app :class="siteMode === 'UH' ? 'signup-app-uh' : 'signup-app'">
    <v-container
      style="height: 100vh"
      class="d-flex align-center justify-center signup-container"
    >
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
      </v-overlay>
      <v-card v-show="verified">
        <v-card-title>Thank you for verifying your email address</v-card-title>
        <v-card-text>
          <v-btn v-if="!isAuth" block color="primary" @click="navigate('login')"
            >Login</v-btn
          >
          <v-btn
            v-if="isAuth"
            color="primary"
            block
            @click="navigate('appointments')"
            >Return to Dashboard</v-btn
          >
        </v-card-text>
      </v-card>
      <v-card v-if="error">
        <v-card-title>{{ error }}</v-card-title>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import * as fb from '@/firebase'
import PublicLayout from '@/layouts/PublicLayout'

export default {
  name: 'Verify',

  data() {
    return {
      isAuth: false,
      verified: false,
      loading: false,
      siteMode: process.env.VUE_APP_SITE,
      code: '',
      error: ''
    }
  },
  watch: {
    '$route.query.code': {
      immediate: true,
      handler() {
        if (fb.auth.currentUser) {
          this.isAuth = true
        }
        if (
          this.$route.query.code !== '' &&
          this.$route.query.code !== null &&
          this.$route.query.code
        ) {
          this.code = this.$route.query.code
          this.verifyEmail()
        } else {
          this.error = 'Sorry, we could not find your account.'
        }
      }
    }
  },
  created() {
    this.$emit(`update:layout`, PublicLayout)
  },
  methods: {
    async verifyEmail() {
      this.loading = true
      const verifyProxy = fb.functions.httpsCallable('verifyProxy')
      try {
        await verifyProxy({
          userId: this.code
        })
        this.verified = true
        this.loading = false
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
    navigate(route) {
      if (this.$route.path !== route) {
        this.$router.replace(route)
      }
    }
  }
}
</script>
